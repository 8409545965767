import React, { useMemo, useState } from 'react';

import Grid from '@mui/material/Grid';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { meshGatewayClient } from 'src/apollo/client';
import { useOrgId } from 'src/apollo/local-state';
import AdminTable, { DataCols } from 'src/components/admin-table/admin-table';
import Button from 'src/components/button/button';
import ExpandedMenu from 'src/components/expanded-menu/expanded-menu';
import Icon from 'src/components/icon/icon';
import TextInput from 'src/components/input/input';
import { useDebounce } from 'src/components/membership/membership';
import PageHeader from 'src/components/page-header/page-header';
import Panel from 'src/components/panel/panel';
import { useControlledQuery } from 'src/components/table-controls/table-controls';
import { PageMaxWidth } from 'src/components/util-components/util-components';
import {
  GetAreasCoordinatorCount_findOrganisations_results as AreaListItem,
  GetAreasCoordinatorCount,
  GetAreasCoordinatorCountVariables,
} from 'src/graphql-types/GetAreasCoordinatorCount';
import { PropertySortInput } from 'src/graphql-types/globalTournamentTypes';
import { OrganisationFilterOptionsInput, OrganisationTypeEnum } from 'src/graphql-types/globalUstaTypes';
import getSortOptions from 'src/utils/helper/sortOptions';

import { useAreaModal } from './area-helpers';
import AreaFormModal from './areaFormModal';
import { GET_AREAS_COORDINATOR_COUNT } from './areas-queries';
import * as styles from './manage-areas.module.less';

interface GetAreasCoordinatorCountVariablesWithPagination extends GetAreasCoordinatorCountVariables {
  offset: number;
  limit: number;
  sorts?: PropertySortInput[];
}

interface IAreasList {
  parentOrganisationId?: string;
  showHeader?: boolean;
}

const AreasList: React.FC<IAreasList> = ({ parentOrganisationId, showHeader = true }) => {
  const { areaModal, closeAreaModal, openAreaModal } = useAreaModal<AreaListItem>();
  const [search, setSearch] = useState('');
  const { t } = useTranslation();
  const searchFilter = useDebounce(search, 500);
  const orgId = useOrgId();
  const filters = useMemo(() => {
    const defaultFilters: OrganisationFilterOptionsInput = {
      organisationType: { eq: OrganisationTypeEnum.AREA },
      parentOrganisationId: parentOrganisationId ?? orgId,
    };
    return {
      ...defaultFilters,
      ...(searchFilter && { search: searchFilter }),
    };
  }, [orgId, searchFilter, parentOrganisationId]);

  const { data, loading, controlProps, refetch } = useControlledQuery<
    GetAreasCoordinatorCount,
    GetAreasCoordinatorCountVariablesWithPagination
  >(GET_AREAS_COORDINATOR_COUNT, {
    client: meshGatewayClient,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
    getTotalItems: (org: GetAreasCoordinatorCount) => org.findOrganisations.total ?? 0,
    notifyOnNetworkStatusChange: true,
    transformVariables: (v: GetAreasCoordinatorCountVariablesWithPagination) => {
      const { limit, offset, sorts } = v;
      const sortOptions = getSortOptions(sorts);

      return {
        pageArgs: { limit, skip: offset },
        filter: filters,
        ...(sortOptions && { sort: sortOptions }),
      };
    },
  });
  const areasList = useMemo(
    () =>
      data?.findOrganisations.results.map((org) => ({
        ...org,
        id: org.organisationId,
      })) ?? [],
    [data?.findOrganisations?.results],
  );

  const columns: DataCols<AreaListItem & { id: string }> = [
    {
      key: 'organisationName',
      getValue: (org) => <Link to={`/areas/${org.organisationId}`}>{org.organisationName}</Link>,
      title: t('area'),
    },
    {
      key: 'areaCoordinators',
      getValue: (org) => org?.roleCounts?.counts?.find((role) => role.role === 'teamcoordinator')?.count ?? 0,
      title: t('area coordinators'),
      sort: false,
    },
    {
      key: 'Actions',
      getValue: (org) => (
        <ExpandedMenu
          items={[
            {
              key: 'edit',
              label: t('edit area'),
              onClick: () => handleEditClick(org),
            },
          ]}
          buttonText=""
          buttonIcon
          iconName="md-more-options"
          anchorElement="icon"
        />
      ),
      title: t('actions'),
      sort: false,
    },
  ];
  const handleEditClick = (area: AreaListItem) => {
    openAreaModal({
      areaToEdit: area,
    });
  };

  const handleAreaCreated = () => {
    refetch();
    closeAreaModal();
  };

  return (
    <PageMaxWidth>
      {showHeader && (
        <Grid container justifyContent="space-between" alignItems="center">
          <PageHeader title={t('manage areas')} />
          <Button spacing={{ margins: { sm: 'bottom' } }} onClick={() => openAreaModal()}>
            <Icon name="sm-add" className={styles.addIcon} />
            {t('add area')}
          </Button>
        </Grid>
      )}
      <Panel extendedPadding>
        <div className={styles.searchContainer}>
          <TextInput
            placeholder={t('search area')}
            value={search}
            onChange={(t) => setSearch(t?.target.value)}
            fullWidth
            disableUnderline
            outlined
            onClearClick={search ? () => setSearch('') : undefined}
          />
        </div>
        <AdminTable
          data={areasList}
          loading={loading}
          columns={columns}
          controls={controlProps}
          hideTopPaginationInfo
          filters={filters}
        />
      </Panel>
      <AreaFormModal {...areaModal} onCancel={closeAreaModal} onAreaCreated={handleAreaCreated} />
    </PageMaxWidth>
  );
};

export default AreasList;
