import React, { FC, useCallback, useState } from 'react';

import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { meshGatewayClient } from 'src/apollo/client';
import { ADD_TEAM_TO_AREA } from 'src/components/areas/areas-queries';
import Button from 'src/components/button/button';
import CustomDialog from 'src/components/custom-dialog/custom-dialog';
import { getEnvConfig } from 'src/config/config';
import { AddTeamToArea, AddTeamToAreaVariables } from 'src/graphql-types/addTeamToArea';
import { getToken } from 'src/utils/auth';

import { TeamSearch, useTeamSearchData } from '@clubspark-react/player-search/teamSearch';

import * as style from './area-teams.module.less';

type AddTeamModalProps = {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  areaId: string;
  onSuccess: () => void;
  seasonId: string;
};

const AddTeamModal: FC<AddTeamModalProps> = ({ open, setOpen, areaId, onSuccess, seasonId }) => {
  const { t } = useTranslation();
  const token = getToken();
  const queryUrl = getEnvConfig().MESH_GATEWAY_GQL_URL;
  const [selectedTeamIds, setSelectedTeamIds] = useState<string[]>([]);

  const [addTeamToArea, { loading }] = useMutation<AddTeamToArea, AddTeamToAreaVariables>(ADD_TEAM_TO_AREA, {
    client: meshGatewayClient,
    update: (cache) => {
      cache.evict({ fieldName: 'findRoster' });
    },
  });

  const {
    data: teamSearchData = [],
    isLoading: isLoadingTeamSearch,
    getSearchResults: getTeamSearchResults,
    fetchMore,
  } = useTeamSearchData({
    queryUrl,
    authToken: token ?? undefined,
    fetchPolicy: 'cache-first',
  });
  const handleSetSelectedTeamIds = useCallback((teamId: string) => {
    setSelectedTeamIds([teamId]);
  }, []);

  const inviteTeams = useCallback(() => {
    addTeamToArea({
      variables: {
        rosterId: selectedTeamIds[0],
        areaId,
        seasonId,
      },
    }).then(() => {
      onSuccess();
    });
  }, [addTeamToArea, areaId, onSuccess, seasonId, selectedTeamIds]);

  return (
    <CustomDialog
      title={t('find team')}
      titleStyle={style.addModalTitle}
      open={open}
      maxWidth={'md'}
      onClose={() => setOpen(false)}
      actions={
        <Button
          spacing={{ margins: { sm: 'all' } }}
          onClick={inviteTeams}
          loading={loading}
          disabled={!selectedTeamIds?.length || loading}
        >
          {t('add team')}
        </Button>
      }
      content={
        <TeamSearch
          data={teamSearchData}
          isLoading={isLoadingTeamSearch}
          selectedTeamIds={selectedTeamIds}
          onSearch={getTeamSearchResults}
          onSelectTeams={handleSetSelectedTeamIds}
          onLoadMore={fetchMore}
        />
      }
    />
  );
};

export default AddTeamModal;
