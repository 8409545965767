import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';
import ButtonModal from 'src/components/modal/modal';

import { BodyRegularBold } from '@clubspark-react/clubspark-react-tools';

type RemoveTeamConfirmationModalProps = {
  visible: boolean;
  onVisibleChange: (isOpen: boolean) => void;
  onRemove: () => void;
};

const RemoveTeamConfirmationModal: FC<RemoveTeamConfirmationModalProps> = ({ visible, onVisibleChange, onRemove }) => {
  const { t } = useTranslation();

  return (
    <ButtonModal
      actionButtons={[
        {
          id: 'remove',
          content: t('remove'),
          props: {
            type: 'submit',
            level: 'warning',
            onClick: onRemove,
          },
        },
      ]}
      show={visible}
      cancelButton
      title={t('remove team')}
      openButton={{ hidden: true }}
      onVisibilityChange={onVisibleChange}
      hideCloseButton
    >
      <BodyRegularBold>{t('confirm remove team')}</BodyRegularBold>
    </ButtonModal>
  );
};

export default RemoveTeamConfirmationModal;
