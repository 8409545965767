import React from 'react';

import { RouteComponentProps } from '@reach/router';
import AreaDetails from 'src/components/areas/area-details/area-details';
import Layout from 'src/components/layout/layout';
import { PrivateRoute } from 'src/components/private-route/private-route';
import SEO from 'src/components/seo';

interface AreaDetailsRouteProps extends RouteComponentProps {
  areaIds?: string;
}

const AreaDetailsRoute: React.FC<AreaDetailsRouteProps> = ({ areaIds = '' }) => {
  const ids = areaIds?.split('/');

  const primaryAreaId = ids[ids.length - 1];

  if (!areaIds || !primaryAreaId) {
    return null;
  }

  return (
    <Layout>
      <SEO title="Area Details" />
      <PrivateRoute>
        <AreaDetails areaId={primaryAreaId} />
      </PrivateRoute>
    </Layout>
  );
};

export default AreaDetailsRoute;
