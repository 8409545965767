import React, { FC, useMemo, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { meshGatewayClient } from 'src/apollo/client';
import AdminTable, { DataCols } from 'src/components/admin-table/admin-table';
import { GET_AREA_TEAMS, GET_CURRENT_SEASON, REMOVE_TEAM_FROM_AREA } from 'src/components/areas/areas-queries';
import * as styles from 'src/components/areas/manage-areas.module.less';
import Button from 'src/components/button/button';
import Dropdown from 'src/components/dropdown/dropdown';
import ExpandedMenu from 'src/components/expanded-menu/expanded-menu';
import Icon from 'src/components/icon/icon';
import Panel from 'src/components/panel/panel';
import { useControlledQuery } from 'src/components/table-controls/table-controls';
import { PageMaxWidth } from 'src/components/util-components/util-components';
import { GetAreaLeaguesVariables } from 'src/graphql-types/GetAreaLeagues';
import { GetAreaTeams, GetAreaTeams_findRoster_results } from 'src/graphql-types/GetAreaTeams';
import { GetCurrentSeason } from 'src/graphql-types/GetCurrentSeason';
import { PropertySortInput } from 'src/graphql-types/globalTournamentTypes';
import { RemoveTeamFromArea, RemoveTeamFromAreaVariables } from 'src/graphql-types/RemoveTeamFromArea';
import { GET_SEASONS } from 'src/queries/ITA';
import getSortOptions from 'src/utils/helper/sortOptions';

import AddTeamModal from './add-team-modal';
import RemoveTeamConfirmationModal from './remove-team-confirmation-modal';

interface AreaTeamsPaginationProps extends GetAreaLeaguesVariables {
  offset: number;
  limit: number;
  sorts?: PropertySortInput[];
}
type AreaTeamsProp = {
  areaId?: string;
};
const AreaTeams: FC<AreaTeamsProp> = ({ areaId }) => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [teamToRemove, setTeamToRemove] = useState('');
  const [seasonId, setSeasonId] = useState('');

  const { loading: getCurrentSeasonLoading } = useQuery<GetCurrentSeason>(GET_CURRENT_SEASON, {
    client: meshGatewayClient,
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ getCurrentSeason }) => {
      setSeasonId(getCurrentSeason.id);
    },
  });

  const {
    data: areaTeams,
    loading,
    controlProps,
  } = useControlledQuery<GetAreaTeams, AreaTeamsPaginationProps>(GET_AREA_TEAMS, {
    client: meshGatewayClient,
    getTotalItems: (teams: GetAreaTeams) => teams?.findRoster?.total ?? 0,
    notifyOnNetworkStatusChange: true,
    skip: !seasonId,
    transformVariables: (v: AreaTeamsPaginationProps) => {
      const { offset, limit, sorts } = v;
      const sortOptions = getSortOptions(sorts);

      return {
        pagination: { limit, skip: offset },
        filter: {
          associatedOrganisation: {
            organisationId: { eq: areaId },
            seasonId: { eq: seasonId },
          },
        },
        ...(sortOptions && { sort: sortOptions }),
      };
    },
  });

  const [removeTeamFromArea, { loading: removeTeamLoading }] = useMutation<
    RemoveTeamFromArea,
    RemoveTeamFromAreaVariables
  >(REMOVE_TEAM_FROM_AREA, {
    client: meshGatewayClient,
    update: (cache) => {
      cache.evict({ fieldName: 'findRoster' });
    },
  });

  const { data: { listSeasons = [] } = {} } = useQuery(GET_SEASONS, {
    client: meshGatewayClient,
  });

  const seasons = listSeasons.map((season) => ({
    label: season.name,
    value: season.id,
  }));

  const teams = useMemo(
    () => areaTeams?.findRoster?.results?.map((team) => ({ ...team, id: team.groupId })),
    [areaTeams?.findRoster?.results],
  );

  const columns: DataCols<GetAreaTeams_findRoster_results & { id: string }> = [
    {
      key: 'groupFullName',
      getValue: (item) => item.groupFullName ?? item.groupName,
      title: t('name'),
    },
    {
      key: 'Actions',
      getValue: (item) => (
        <ExpandedMenu
          items={[
            {
              key: 'remove',
              label: t('remove'),
              onClick: () => {
                setTeamToRemove(item.id);
                setConfirmationModalOpen(true);
              },
              warning: true,
            },
          ]}
          buttonText={''}
          buttonIcon
          iconName="md-more-options"
          anchorElement="icon"
        />
      ),
      title: t('actions'),
      sort: false,
    },
  ];

  const removeTeam = () => {
    setConfirmationModalOpen(false);
    removeTeamFromArea({
      variables: {
        rosterId: teamToRemove,
        areaId: areaId ?? '',
        seasonId,
      },
      onCompleted: () => setTeamToRemove(''),
    });
  };

  const handleTeamAddedToArea = () => {
    setModalOpen(false);
  };

  return (
    <PageMaxWidth>
      <Panel extendedPadding>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Dropdown
              options={seasons}
              onSelect={({ value }) => {
                setSeasonId(value);
              }}
              selected={seasonId}
              data-testid="seasons-select"
            />
          </Grid>
          <Grid item>
            <Button
              spacing={{ margins: { sm: 'bottom' } }}
              testId={'add-team-to-area-button'}
              onClick={() => setModalOpen(true)}
            >
              <Icon name="sm-add" className={styles.addIcon} />
              {t('add team')}
            </Button>
          </Grid>
        </Grid>
        <AdminTable
          hideTopPaginationInfo
          data={teams ?? []}
          loading={loading || removeTeamLoading || getCurrentSeasonLoading}
          columns={columns}
          controls={controlProps}
        />
      </Panel>

      {modalOpen && (
        <AddTeamModal
          open={modalOpen}
          setOpen={setModalOpen}
          areaId={areaId ?? ''}
          seasonId={seasonId}
          onSuccess={handleTeamAddedToArea}
        />
      )}

      <RemoveTeamConfirmationModal
        visible={confirmationModalOpen}
        onVisibleChange={setConfirmationModalOpen}
        onRemove={removeTeam}
      />
    </PageMaxWidth>
  );
};

export default AreaTeams;
