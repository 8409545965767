import React, { FC, useState } from 'react';

import { meshGatewayClient } from 'src/apollo/client';
import * as styles from 'src/components/areas/manage-areas.module.less';
import LeaguesFilters, { AvailableFilters, LeagueFilters } from 'src/components/leagues/leagues-filters';
import { GET_LEAGUES } from 'src/components/leagues/leagues-queries';
import LeaguesTable from 'src/components/leagues/leagues-table/leagues-table';
import Panel from 'src/components/panel/panel';
import { useControlledQuery } from 'src/components/table-controls/table-controls';
import { PageMaxWidth } from 'src/components/util-components/util-components';
import { GetAreaLeagues } from 'src/graphql-types/GetAreaLeagues';
import { GetLeagues, GetLeaguesVariables } from 'src/graphql-types/GetLeagues';

interface AreaLeaguesPaginationProps extends GetLeaguesVariables {
  offset: number;
  limit: number;
}

type AreaLeaguesProps = {
  areaId?: string;
};

const AreaLeagues: FC<AreaLeaguesProps> = ({ areaId }) => {
  const [seasonId, setSeasonId] = useState<string>();

  const {
    data: areaLeagues,
    loading,
    controlProps,
  } = useControlledQuery<GetAreaLeagues, AreaLeaguesPaginationProps>(GET_LEAGUES, {
    client: meshGatewayClient,
    getTotalItems: (area: GetLeagues) => area?.leagues?.totalItems ?? 0,
    notifyOnNetworkStatusChange: true,
    skip: !seasonId,
    transformVariables: ({ limit, offset }: AreaLeaguesPaginationProps) => {
      return {
        filter: { parentOrganisationId: areaId, seasonId },
        pagination: { limit, skip: offset },
      };
    },
  });

  const handleFilters = (filters: LeagueFilters) => {
    setSeasonId(filters.seasonId);
  };

  return (
    <PageMaxWidth>
      <Panel extendedPadding>
        <div className={styles.filterContainer}>
          <LeaguesFilters
            excludedFilters={[AvailableFilters.SEARCH, AvailableFilters.CATEGORY, AvailableFilters.STATUS]}
            onFilterChange={handleFilters}
          />
        </div>
        <LeaguesTable leagues={areaLeagues?.leagues?.items} controlProps={controlProps} loading={loading} />
      </Panel>
    </PageMaxWidth>
  );
};

export default AreaLeagues;
