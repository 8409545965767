import React, { useMemo, useState } from 'react';

import { useQuery } from '@apollo/client';
import Grid from '@mui/material/Grid';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { meshGatewayClient } from 'src/apollo/client';
import { useOrgId } from 'src/apollo/local-state';
import { useAreaModal } from 'src/components/areas/area-helpers';
import AreaFormModal from 'src/components/areas/areaFormModal';
import { GET_AREA_DATA_WITH_HIERARCHY } from 'src/components/areas/areas-queries';
import AreasList from 'src/components/areas/manage-areas';
import Breadcrumbs, { type IPath } from 'src/components/breadcrumbs/breadcrumbs';
import ExpandedMenu from 'src/components/expanded-menu/expanded-menu';
import Panel from 'src/components/panel/panel';
import TabPanel from 'src/components/tab-panel/tab-panel';
import MUITabs from 'src/components/tabs/tabs';
import { Body, H2 } from 'src/components/typography/typography';
import { PageMaxWidth } from 'src/components/util-components/util-components';
import {
  GetAreaDataWithHierarchy,
  GetAreaDataWithHierarchyVariables,
} from 'src/graphql-types/GetAreaDataWithHierarchy';
import ROUTES from 'src/utils/routes';

import AreaCoordinators from './area-coordinators/area-coordinators';
import AreaLeagues from './area-leagues/area-leagues';
import AreaTeams from './area-teams/area-teams';

interface AreaDetailsProps {
  areaId?: string;
}

const AreaDetails: React.FC<AreaDetailsProps> = ({ areaId }) => {
  const { t } = useTranslation();
  const orgId = useOrgId();

  const { areaModal, closeAreaModal, openAreaModal } =
    useAreaModal<GetAreaDataWithHierarchy['getOrganisationByIdWithHierarchy']>();
  const [tab, setTab] = useState(0);

  const { data: area, refetch: refetchArea } = useQuery<GetAreaDataWithHierarchy, GetAreaDataWithHierarchyVariables>(
    GET_AREA_DATA_WITH_HIERARCHY,
    {
      client: meshGatewayClient,
      variables: { organisationId: areaId as string },
      onCompleted: (data) => {
        if (!data?.getOrganisationByIdWithHierarchy) {
          navigate('/404');
        }
      },
    },
  );
  const paths = useMemo(() => {
    const links: IPath[] =
      area?.getOrganisationByIdWithHierarchy?.populatedHierarchy
        ?.filter((org) => org.organisationId !== orgId)
        .map((org) => ({
          name: org.organisationName ?? '',
          to: `${ROUTES.AREAS}/${org.organisationId}`,
          active: false,
        })) ?? [];
    links.push({
      name: area?.getOrganisationByIdWithHierarchy?.organisationName ?? '',
      active: true,
    });
    links.unshift({ name: t('manage areas'), to: `${ROUTES.AREAS}`, highlight: true });
    return links;
  }, [
    t,
    orgId,
    area?.getOrganisationByIdWithHierarchy?.organisationName,
    area?.getOrganisationByIdWithHierarchy?.populatedHierarchy,
  ]);

  const handleAreaCreated = () => {
    refetchArea();
    closeAreaModal();
  };

  const handleTabChange = (event: React.ChangeEvent<Record<string, any>>, newValue: number) => {
    setTab(newValue);
  };

  return (
    <PageMaxWidth>
      <Breadcrumbs paths={paths} />
      <Panel extendedPadding>
        <Grid container>
          <Grid container item xs alignItems="center" justifyContent="space-between">
            <H2 spacing={{ margins: { sm: 'right' } }} noMargin>
              {area?.getOrganisationByIdWithHierarchy?.organisationName}
            </H2>
            <ExpandedMenu
              items={[
                {
                  key: 'add',
                  label: t('add area'),
                  onClick: () =>
                    openAreaModal({
                      parentAreaId: area?.getOrganisationByIdWithHierarchy?.organisationId,
                    }),
                },
                {
                  key: 'edit',
                  label: t('edit area'),
                  onClick: () => {
                    if (!area?.getOrganisationByIdWithHierarchy) {
                      return;
                    }
                    openAreaModal({
                      areaToEdit: area?.getOrganisationByIdWithHierarchy,
                    });
                  },
                },
              ]}
              buttonText={t('area options')}
              buttonIcon
              iconName="sm-down"
              anchorElement="button"
            />
          </Grid>
          <Grid container>
            <Body size="md">
              Area owner:{' '}
              <strong>{area?.getOrganisationByIdWithHierarchy?.parentOrganisation?.organisationName}</strong>
            </Body>
          </Grid>
        </Grid>
      </Panel>

      <MUITabs
        value={tab}
        handleChange={handleTabChange}
        labels={[t('leagues'), t('teams'), t('coordinators'), t('sub-areas')]}
      />
      <TabPanel value={tab} index={0}>
        <AreaLeagues areaId={areaId} />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <AreaTeams areaId={areaId} />
      </TabPanel>
      <TabPanel value={tab} index={2}>
        <AreaCoordinators area={area} areaId={areaId} />
      </TabPanel>
      <TabPanel value={tab} index={3}>
        <AreasList parentOrganisationId={areaId} showHeader={false} />
      </TabPanel>

      <AreaFormModal {...areaModal} onCancel={closeAreaModal} onAreaCreated={handleAreaCreated} />
    </PageMaxWidth>
  );
};

export default AreaDetails;
