import React, { useMemo, useState } from 'react';

import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { meshGatewayClient } from 'src/apollo/client';
import AreaFilters from 'src/components/areas/area-details/area-filters';
import AreaSearch from 'src/components/areas/area-details/area-search';
import { GET_AREA_COORDINATORS } from 'src/components/areas/areas-queries';
import * as styles from 'src/components/areas/manage-areas.module.less';
import InviteCoordinatorModal from 'src/components/areas/manage-coordinators/invite-coordinator-modal';
import RemoveAreaCoordinatorModal from 'src/components/areas/removeAreaCoordinatorModal';
import Button from 'src/components/button/button';
import CoordinatorsTable from 'src/components/coordinators-table/coordinators-table';
import Icon from 'src/components/icon/icon';
import Panel from 'src/components/panel/panel';
import SuccessNotification from 'src/components/success-notification/success-notification';
import { useControlledQuery } from 'src/components/table-controls/table-controls';
import { PageMaxWidth } from 'src/components/util-components/util-components';
import { FindAreas_findOrganisations_results } from 'src/graphql-types/FindAreas';
import {
  GetAreaCoordinators_resourcesByEntityId_results as CoordinatorListItem,
  GetAreaCoordinators,
  GetAreaCoordinatorsVariables,
} from 'src/graphql-types/GetAreaCoordinators';
import { GetAreaDataWithHierarchy } from 'src/graphql-types/GetAreaDataWithHierarchy';
import { PropertySortInput } from 'src/graphql-types/globalTournamentTypes';
import { SafePlayStatus } from 'src/graphql-types/globalUstaTypes';
import { useDebounce } from 'src/hooks/use-debounce';
import getSortOptions from 'src/utils/helper/sortOptions';
import { NavigationState } from 'src/utils/typedefs/navigation';

interface AreaCoordinatorsProps {
  areaId?: string;
  area: GetAreaDataWithHierarchy | undefined;
}

interface GetAreaCoordinatorsVariablesWithPagination extends GetAreaCoordinatorsVariables {
  offset: number;
  limit: number;
  sorts?: PropertySortInput[];
}

const AreaCoordinators: React.FC<AreaCoordinatorsProps> = ({ areaId, area }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const [search, setSearch] = useState('');
  const [inviteStatus, setInviteStatus] = useState('');
  const [safePlay, setSafePlay] = useState('');
  const [selectedCoordinator, setSelectedCoordinator] = useState<CoordinatorListItem | undefined>();
  const [showModal, setShowModal] = useState(false);
  const searchFilter = useDebounce(search, 500);
  const [showInviteCoordinatorModal, setShowInviteCoordinatorModal] = useState(false);

  const filter = useMemo(() => {
    const safePlayStatuses =
      safePlay === 'NOT CERTIFIED'
        ? [SafePlayStatus.NOTSET, SafePlayStatus.NOTAPPROVED, SafePlayStatus.PENDING]
        : [safePlay];

    return {
      entityIds: [areaId],
      entitySubTypes: ['AREA'],
      includeChildren: false,
      ...(searchFilter && { fullName: searchFilter }),
      ...(safePlay && { safePlayStatuses: safePlayStatuses }),
      ...(inviteStatus && { roleStates: [inviteStatus] }),
    };
  }, [areaId, inviteStatus, safePlay, searchFilter]);

  const {
    data: areaCoordinators,
    loading,
    controlProps,
    refetch,
  } = useControlledQuery<GetAreaCoordinators, GetAreaCoordinatorsVariablesWithPagination>(GET_AREA_COORDINATORS, {
    client: meshGatewayClient,
    getTotalItems: (area: GetAreaCoordinators) => area.resourcesByEntityId?.total ?? 0,
    notifyOnNetworkStatusChange: true,
    transformVariables: ({ limit, offset, sorts }: GetAreaCoordinatorsVariablesWithPagination) => {
      const sort = getSortOptions(sorts);
      return {
        filter,
        pageArgs: { limit, skip: offset },
        ...(sort && { sort }),
      };
    },
  });

  const coordinatorsList = useMemo(
    () =>
      areaCoordinators?.resourcesByEntityId?.results.map((coordinator) => ({
        ...coordinator,
        id: coordinator.userId,
      })) ?? [],
    [areaCoordinators?.resourcesByEntityId?.results],
  );
  const handleRemoveClick = (coordinator: CoordinatorListItem) => {
    setSelectedCoordinator(coordinator);
    setShowModal(true);
  };
  const handleSuccess = () => {
    const state: NavigationState = {
      popup: {
        message: t('invite area coordinator success'),
      },
    };
    navigate(pathname, { state });
    refetch();
  };

  return (
    <PageMaxWidth>
      <Panel
        extendedPadding
        title={t('coordinators')}
        headerEndContent={
          <Button spacing={{ margins: { sm: 'bottom' } }} onClick={() => setShowInviteCoordinatorModal(true)}>
            <Icon name="sm-add" className={styles.addIcon} />
            {t('invite coordinator')}
          </Button>
        }
      >
        <div className={styles.filterContainer}>
          <AreaSearch search={search} setSearch={setSearch} placeholder={t('search name')} />
          <AreaFilters
            inviteStatus={inviteStatus}
            setInviteStatus={setInviteStatus}
            safePlay={safePlay}
            setSafePlay={setSafePlay}
          />
        </div>
        <CoordinatorsTable
          hideTopPaginationInfo
          hideAreaLeagueColumn
          type={'area'}
          data={coordinatorsList}
          loading={loading}
          controls={controlProps}
          onRemove={handleRemoveClick}
          filters={filter}
        />
        <RemoveAreaCoordinatorModal
          onSuccess={() => refetch()}
          areaOwnerId={selectedCoordinator?.organisation?.ownerId}
          organisationId={areaId ?? ''}
          coordinatorId={selectedCoordinator?.userId ?? ''}
          visible={showModal}
          onVisibleChange={setShowModal}
        />
        <InviteCoordinatorModal
          show={showInviteCoordinatorModal}
          setShowInviteCoordinatorModal={setShowInviteCoordinatorModal}
          areas={
            area
              ? [area?.getOrganisationByIdWithHierarchy as unknown as FindAreas_findOrganisations_results]
              : undefined
          }
          onSuccess={handleSuccess}
        />
      </Panel>
      <SuccessNotification atUrl={pathname} hideAfterMs={5000} />
    </PageMaxWidth>
  );
};

export default AreaCoordinators;
