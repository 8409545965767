import React from 'react';

import { Box } from '@mui/material';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
  className?: string;
  disableUnmount?: boolean;
}

const TabPanel = ({ children, className, value, index, disableUnmount = false }: TabPanelProps) => {
  const shouldRenderChildren = disableUnmount || value === index;
  return (
    <div hidden={value !== index} className={className}>
      {shouldRenderChildren && <Box p={{ xs: 0, sm: 0, md: 0 }}>{children}</Box>}
    </div>
  );
};

export default TabPanel;
